.maillistsMain {
  /* box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important; */
  /* padding: 10px; */
}
.maillistTableMain {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  background-color: white;
  height: 100vh;
  min-width: 400px;
}
.maillistsLoading {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  width: 100%;
}
.MuiTableCell-root.MuiTableCell-head.mailListHeader.MuiTableCell-alignLeft.MuiTableCell-stickyHeader {
  background-color: white !important;
}

/* .maillistsMain.MuiTableRow-root.Mui-selected
  .MuiTableRow-root.Mui-selected:hover {
  background-color: red !important;
} */

.MuiToolbar-root.MuiToolbar-regular.maillistToolbar.MuiToolbar-gutters {
  padding: 5px !important;
}
/* .MuiTableRow-root.MuiTableRow-hover.Mui-selected {
  background-color: rgba(0, 171, 247, 1) !important;
  background-color: red !important;
  color: white;
} */
.maillistCells {
  border-bottom: none !important;
}

.mailListsRow:hover {
  background-color: rgba(25, 37, 46, 0.08) !important;
  cursor: pointer;
}
.mailListsRow {
  &.MuiTableRow-root.Mui-selected {
    background-color: #D5EDFA !important
  }
}

.maillistIconCells {
  border-bottom: none !important;
  width: 24px;
}
.mailListHeader {
  border-bottom: none !important;
}
div.newMaillistForm {
  padding: 20px 47px;
}
.maillistName .selectedMaillistAddress .maillistGroupName {
  font-size: 14px;
  width: 100%;
}
.MuiFormControl-root.MuiTextField-root.maillistName {
  padding-right: 10px !important;
}
.MuiFormControl-root.MuiTextField-root.maillistGroupName {
  width: 100% !important;
}
.MuiFormControl-root.MuiTextField-root.maillistDomainEmail {
  width: 100% !important;
  color: black !important;
}
.MuiInputBase-root {
  color: rgba(0, 0, 0, 0.54);
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  width: 100% !important;
}
.grid-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkbox-line {
  display: flex;
  flex-direction: row;
  margin-top: 32px !important;
}

.newListTitle {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.membersTitle {
  margin-top: 32px;
  left: 0px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.underMailboxFieldError {
  color: #d43a3e;
  line-height: 24px;
  margin-left: 320px !important;
  width: 100%;
}

/* .MuiChip-root.Mui-disabled {
  opacity: 1 !important;
  pointer-events: none;
} */

.chip {
  & .MuiChip-root.Mui-disabled .MuiChip-deleteIcon {
    display: none;
    opacity: 1 !important;
  }
  & .MuiChip-root.Mui-disabled {
    opacity: 1 !important;
  }
  & .MuiInput-underline.Mui-disabled:before {
    display: none;
  }
}
.chipEdit {
  opacity: 1;
}

.autocompleteButton {
  & .MuiIconButton-root.Mui-disabled {
    display: none;
  }
}

.checkbox_text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2b363f;
  margin-left: 8px;
}

.grid-container--fit {
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}
/* 
 .grid-element:after {
  content: "";
  display: flex;
  height: 100%;
  /* vertical-align: bottom; */
/* }  */
*/ hr {
  margin: 80px;
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
  font-size: 14px !important;
  padding-bottom: 9px;
}

.wrapper {
  display: grid;
  height: 100vh;
  width: 100%;
  grid-template-rows: 1fr 9fr;
}
