.DomainsTable {
    /* display: flex; */
    flex-direction: column;
    width: 100%;
    height: 100vh;
    /* box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12); */
    background-color: white;
    border-color:white;
    overflow-y: hidden;
   
  }

  .NewDomainRoot {  
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    /* box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12); */
    background-color: white;  
    padding-left: 24px;
    padding-top: 24px; 
    padding-bottom: 24px;
    overflow-y: scroll;
  }       

  /* .NewDomainRoot .MuiInputBase-root {
    display: block;
  } */

  .NewDomainRootLoading {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    /* box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12); */
    background-color: white;  
    opacity: 40%;
    padding-left: 24px;
    padding-top: 24px; 
  }

  
  .NewDomainTitle2 {
    margin-bottom: 4px;
    color: rgba(25, 37, 46, 0.72);  
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .NewDomainField1 .MuiFormControl-root .MuiOutlinedInput-root{
    width: 400px;
    height: 40px;   
    .Mui-disabled {
      background-color: rgba(25, 37, 46, 0.06);
      border-radius: 4px;
      border-color: rgba(25, 37, 46, 0.08);
      color: rgba(163, 168, 171, 1)
    }
  }

  .NewDomainField1 .MuiOutlinedInput-input {   
    padding-top: 10.5px; 
    /* padding-left: 12px;
    padding-right: 0px; */
    padding-bottom: 10.5px; 
  }
  
  .NewDomainField2 .MuiFormControl-root .MuiOutlinedInput-root{
    width: 400px;
    min-height: 56px; 
    /* max-height: 200px; */
    display: block !important;   
    /* overflow-y: scroll; */
    line-height: 24px;
  }

  .NewDomainField2 .MuiOutlinedInput-multiline {
    padding-top: 8px; 
    padding-left: 12px;
    padding-right: 0px;
    padding-bottom: 8px;
  }

  .underDomainFieldError {
    color:  #D43A3E;    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    margin-left: 8px;
    margin-bottom: 8px;
  }

  .NewDomainInfo {
    display: flex;
    width:400px;
    height: 144px;
    padding: 12px 16px;
    margin-bottom: 20px;
    border-radius: 4px;
    background: rgba(25, 37, 46, 0.06);
    color: #19252E;
    font-size: 16px;
    /* font-style: normal; */
    font-weight: 400;
    line-height: 24px;
  }

  .DomainsGetError {      
    width:400px;
    height: 132px;
    padding: 12px 16px;
    margin-bottom: 20px;
    border-radius: 4px;
    background: #FFF0F0;
    color: #19252E;
    font-size: 16px;
    /* font-style: normal; */
    font-weight: 400;
    line-height: 24px;
  }

  .DomainsAddError {  
    display: flex;    
    width:400px;
    height: 72px;
    padding: 12px 16px;    
    margin-bottom: 20px;
    border-radius: 4px;
    background: #FFF0F0;
    color: #19252E;
    font-size: 16px;
    /* font-style: normal; */
    font-weight: 400;
    line-height: 24px;
  }

  .NewDomainInfoName {
    width: 384px;
    margin-top: 4px;
    margin-left: 8px;
    color:  rgba(25, 37, 46, 0.56);    
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.35px;
  }


  .DomainsTable .toolbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 24px;  
    margin-bottom: 16px;
  }
  .DomainsTable .MuiTable-root{
    height: 64px;
    /* padding-left: 24px; */
  }

  .DomainsTable .MuiTableCell-root {
    height: 64px;
    padding-left: 24px !important;
  }
  .DomainsTable .MuiTableRow-root{
    max-height: 64px !important;  
  }
  .DomainsTable .MuiTableCell-stickyHeader {
    background-color: white;
    /* border-color: white; */
    border-bottom: none;
  }

  .DomainsTable .MuiTableCell-stickyHeader {    
        color: #19252E; 
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;    
} 
.DomainsTable .MuiTableRow-root.MuiTableRow-hover:hover {
    background-color:rgba(25, 37, 46, 0.08) !important; 
  }
  .DomainsTableContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }

  div.MuiDialogTitle-root { 
    padding-bottom: 0px;
    padding-top: 16px;
  }


  .MuiPagination-root.userPagination {
    display: flex;
    flex-grow: 1;
    flex-direction: column-reverse;
    margin-bottom: 25px;
    text-align: center;
    width: fit-content;
  }
  .MuiPagination-ul {
    height: inherit;
    width: fit-content;
  }
  table.DomainsTableCircle {
    max-height: 100%;
    height: inherit;
  }
  td.loadingCell {
    height: 614px;
    text-align: center;
    border-bottom: none;
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: #1486C8 !important;
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #44BBFF !important;
  }
  
  div.domainCell {
    margin-top: 16px;
    max-width: 320px;
    width: 100%;
    padding-right: 50px;
  }
  div.domainCellError {
    margin-top: 16px;
    max-width: 320px;
    width: 100%;
    padding-right: 50px;
    .MuiInput-underline:before {
      border-bottom-color: #E2333A;
    } 
    .MuiSvgIcon-root {
      color:#E2333A
    }
  }
  
  div.roleCell {
    width: 100%;
  }
 
  .container-fluid {
    padding-top: 38px;
  }
  div.newUserForm {
    display: flex;
  
    /* justify-content: center; */
  }
  div.newUser {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    padding: 15px;
  }
  div.backImg {
    padding: 12px 5px;
  }
  div.deleteUserForm {
    display: flex;
    position: absolute;
    right: 47px;
  }
  div.deleteUser {
    font-size: 14px;
    line-height: 20px;
    font-weight: 550;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  div.deleteImg {
    padding: 12px 5px;
  }
  td.MuiTableCell-root.MuiTableCell-body.addNewUserForm {
    border-bottom: none !important;
  }
  td.MuiTableCell-root.MuiTableCell-body.addNewAliasForm {
    border-bottom: none !important;
  }
  div.addNewUserForm {
    padding: 30px 0 0px 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }
  
 
  td.MuiTableCell-root.MuiTableCell-body.deleteUser {
    border-bottom: none !important;
  }
  .MuiTableCell-root {
    padding: 8px !important;
  }
  
 
  
  .addNewUserLine {
    display: flex;
  }
  div.dayCell {
    padding-right: 20px;
  }
  div.monthCell {
    padding-right: 20px;
  }
  div.firstNameCell {
    max-width: 320px;
    width: 100%;
  
    margin-right: 47px;
  }
  div.secondNameCell {
    max-width: 320px;
    width: 100%;
    margin-right: 47px;
  }
  div.patronymicCell {
    max-width: 320px;
    width: 100%;
    margin-right: 47px;
  }
  div.companyCell {
    max-width: 320px;
    width: 100%;
    margin-right: 47px;
  }
  div.contactCell {
    width: 220px;
  }
  div.positionCell {
    max-width: 320px;
    width: 100%;
    margin-right: 47px;
  }
  div.descriptionCell {
    max-width: 320px;
    width: 100%;
    margin-right: 47px;
  }
  div.cityCell {
    max-width: 320px;
    width: 100%;
    margin-right: 47px;
  }
  div.typeCell {
    width: 100px;
    padding-right: 20px;
  }
  label.activeSwitcher {
    margin: 0 !important;
  }
  div.usersPagination {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .table thead th {
    vertical-align: middle !important;
    border: none !important;
  }
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }
  .table th,
  .table td {
    border: none !important;
    vertical-align: middle !important;
    padding: 5px !important;
  }
  .searchUser {
    display: flex;
  }
  td.newUserLineCell {
    padding: 0 !important;
    border: none !important;
  }
  td.newUserLineCellBlue {
    padding: 0 !important;
    border: none !important;
    width: 260px;
  }
  td.newUserLineCellTrash {
    border: none !important;
    vertical-align: middle !important;
    padding: 5px !important;
    width: 52px;
  }
  div.searchIcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  div.imgSearhUser {
    padding-right: 10px;
  }
  div.currentUserForm {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
  }
  td.currentUserForm {
    border: none !important;
  }
  div.currentUser {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  
  div.currentLogin {
    width: 100%;
  }
  
  .addButton {
    margin-left: 47px;
    margin-top: 40px;  
  }
  .avaAndLogin {
    display: flex;
  }
  .loginRaw {
    display: flex;
    height: fit-content;
    position: relative;
    width: 100%;
  }
  .searchLine {
    margin-right: 20px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
    font-size: 12px !important;
  }
  /* .MuiInputBase-input {
    height: fit-content !important;
  } */
  /* .MuiInputBase-input.MuiInput-input {
    font-size: 14px !important;
   
  
  } */
 
  .loginField {
    width: 100%;
    max-width: 320px;  
  }
  
  .loginFieldError {
    width: 100%;
    max-width: 320px;
    .MuiInput-underline:before {
      border-bottom-color: #E2333A;
    }  
  }
  
  .underLoginFieldError {
    color: #D43A3E;
    line-height: 24px;
    margin-left: 8px;
  }
  
  .errorAlias {
    color: #D43A3E;
    line-height: 24px;
    margin-top: 12px;
    margin-left: 181px;
  }
  
  
  .contactRow {
    width: fit-content;
    padding: 5px 0 5px 0;
  }
  .cardWrapper{
    padding-bottom: 10px;
    padding-top: 10px;
    overflow: scroll;
    max-height: 100vh;
    height: 100vh;
  }
 
  .emailDomain {
    position: relative;
    width: 100%;
    display: flex;
  }
  div.row {
    width: 100%;
  }
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
    height: 100%;
  }
  .MuiFormControl-root.MuiTextField-root.email {
    padding-right: 5px;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid  !important;
  }
  .MuiInput-underline:after {
    border-bottom: 0 solid #3f51b5 !important;
  }
  .MuiFormControlLabel-root {
    margin-bottom: 25px;
  }
  .MuiSwitch-thumb {
    margin-top: -3px;
  }
  .recovery-switch {
    display: flex;
    flex-direction: column;
  }
  .MuiPaper-root.searchLine.MuiPaper-elevation1.MuiPaper-rounded {
    background-color: transparent !important;
  }
  .MuiTableCell-root.MuiTableCell-body.userAva {
    width: 40px;
    padding: 8px 20px !important;
    border-bottom: none !important;
  }
 
  .MuiTableCell-root.MuiTableCell-body.domainCells {
    border-bottom: none !important;
    white-space: nowrap;
    overflow-y: hidden;  
    overflow-x: hidden;  
    text-overflow: ellipsis;   
    max-height: 64px;
  }

  /* .MuiInput-underline:before {
    border-bottom: 1px solid rgba(176, 167, 167, 0.42) !important;
  } */
  .results-title {
    margin-left: 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  
  .backButton {
    margin-left: 45px;
  }
  .deleteUserText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: rgb(243, 74, 82);
    font-weight: 600;
    padding: 12px 5px;
  }
  .deleteUserText:hover {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 5px;
  }
  .MuiButtonBase-root.deleteUserBtnIcon {
    margin-right: 25px;
    
  }
  .MuiFormLabel-root.mainEmailLabel {
    color: black;
    position: relative;
    top: 23px;
    padding: 0 5px 0 5px;
  }
  .MuiFormLabel-root.secondEmailLabel {
    display: inline;
    position: relative;
    color: black;
    top: 22px;
    margin: 0 5px
  }
  .backBtnIcon .MuiButton-label {
    text-transform: none;
    font-weight: 600;
  }
  .deleteUserBtnIcon .MuiButton-label {
    text-transform: none;
    font-weight: 600;
    color: red;
  }


  .title {
    color: #2B363F;
    /* font-family: Roboto; */
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .newDomainBtn {
    margin-top: 16px;
    width: 148px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid rgba(25, 37, 46, 0.08);
    cursor: pointer;
    
  }
  
  .newDomainBtn .MuiButton-label{
    color: #19252E;   
    /* font-family: Roboto; */
    font-size: 14px;
    /* font-style: normal; */
    font-weight: 600;
    line-height: 24px; 
    letter-spacing: 0.35px;
    text-transform: none;
    
  }
  
  .newDomainBtn:hover {
     background-color: rgba(68, 187, 255, 0.72) !important;
  }
  
  .MuiButtonBase-root.backBtnIcon {
    margin-left: 25px;
  }
  .MuiButtonBase-root.backBtnIcon:hover {
    background-color: rgba(0, 171, 247, 1) !important;
    color: black !important;
  }
  .MuiButtonBase-root.deleteUserBtnIcon:hover {
    color: black;
  }
  .MuiButtonBase-root.newUserBtn:hover {
    color: black;
  }
  td.DomainsTableCell {
    border-bottom: none !important;
  }
  
  .MuiTableRow-root.userRow:hover {
    background-color: rgba(0, 171, 247, 1) !important;
  }
  
  /* .MuiTableRow-root.userRow.Mui-selected,
  .MuiTableRow-root.userRow.Mui-selected:hover {
    background-color: rgba(0, 161, 247, 0.15) !important;
  } */
  
  .row_recovery-switch{
      padding-top: 0px;
      padding-bottom: 0px;
      margin-top: 30px;
      margin-bottom:16px;
      height:26px
  }
  
  .mainEmailLabel {
    color: black;
    position: relative;
    top:22px;
    margin: 0 5px 0 5px;
  }
  
  