#root {
  display: flex;
  /* justify-content: space-around; */
  overflow: hidden;
}
div.loading {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); */
}

.MuiCircularProgress-root.MuiCircularProgress-indeterminate {
  margin: 0 auto;
  color: #00abf7 ;
}
.MuiList-padding {
  padding-top: 0px !important;
}
.MuiPaper-elevation1 {
    box-shadow: none !important;
}