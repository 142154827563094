.UsersTable {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.UsersTable .toolbar {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;  
}
.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: rgba(0, 161, 247, 0.15) !important;
}
.userTableContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.userNameTitle {
  width: 50%;
  word-wrap: break-word;
  margin-left: 50px;
  font-weight: bold;
}
.MuiPagination-root.userPagination {
  display: flex;
  flex-grow: 1;
  flex-direction: column-reverse;
  margin-bottom: 25px;
  text-align: center;
  width: fit-content;
}
.MuiPagination-ul {
  height: inherit;
  width: fit-content;
}
table.userTableCircle {
  max-height: 100%;
  height: inherit;
}
td.loadingCell {
  text-align: center;
}
.MuiSwitch-colorSecondary.Mui-checked {
  color: #1486C8 !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #44BBFF !important;
}
.addNewUser {
  color: rgba(0, 153, 243, 1);
  padding: 14px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  display: flex;
  width: fit-content;
}
div.ava {  
  height: 128px;
  width: 100px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 32px;
  margin-bottom: 20px;
}
.avaImg {
  height: 100px;
}
div.domainCell {
  margin-top: 16px;
  max-width: 320px;
  width: 100%;
  padding-right: 50px;
}
div.domainCellError {
  margin-top: 16px;
  max-width: 320px;
  width: 100%;
  padding-right: 50px;
  .MuiInput-underline:before {
    border-bottom-color: #E2333A;
  } 
  .MuiSvgIcon-root {
    color:#E2333A
  }
}

div.roleCell {
  width: 100%;
}
.mainInfoAva {
  padding: 0 0 0 47px;
}
.container-fluid {
  padding-top: 38px;
}
div.newUserForm {
  display: flex;

  /* justify-content: center; */
}
div.newUser {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  padding: 15px;
}
div.backImg {
  padding: 12px 5px;
}
div.deleteUserForm {
  display: flex;
  position: absolute;
  right: 47px;
}
div.deleteUser {
  font-size: 14px;
  line-height: 20px;
  font-weight: 550;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
div.deleteImg {
  padding: 12px 5px;
}
td.MuiTableCell-root.MuiTableCell-body.addNewUserForm {
  border-bottom: none !important;
}
td.MuiTableCell-root.MuiTableCell-body.addNewAliasForm {
  border-bottom: none !important;
}
div.addNewUserForm {
  padding: 30px 0 0px 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}
div.contacts {
  padding: 30px 0 10px 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}
div.addNewPhone {
  display: flex;
  color: rgba(0, 153, 243, 1);
  padding: 18px 14px 0 0;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  margin-right: 15px;
}
div.addAlias {
  color: rgba(0, 153, 243, 1);
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
div.addNewAlias {
  display: flex;
}
div.addPhone {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
td.MuiTableCell-root.MuiTableCell-body.ava {
  border-bottom: none !important;
}
td.MuiTableCell-root.MuiTableCell-body.deleteUser {
  border-bottom: none !important;
}
.MuiTableCell-root {
  padding: 8px !important;
}

.recovery-switch .MuiTypography-root {
  margin-left: 10px;
}

.recovery-switch .MuiIconButton-root {
  padding: 0;
  margin: 0;
}
.recovery-switch .MuiSwitch-root {
  width: 30px;
  height: 20px;
  padding: 0;
  display: flex
}
.recovery-switch .MuiSwitch-switchBase {
  padding: 4px;
  color: grey;
}
.recovery-switch .MuiSwitch-switchBase.Mui-checked {
  padding: 4px;
  color: dimgray;
  transform: translateX(11px);
}
.recovery-switch .MuiSwitch-thumb {
  width: 12px;
  height: 12px;
  margin: 0;
  box-shadow: none;
}
.recovery-switch .MuiSwitch-track {
  border: 1px solid darkgray;
  border-radius: 10px;
  opacity: 1;
  background-color: white;
}

.addNewUserLine {
  display: flex;
}
div.dayCell {
  padding-right: 20px;
}
div.monthCell {
  padding-right: 20px;
}
div.firstNameCell {
  max-width: 320px;
  width: 100%;

  margin-right: 47px;
}
div.secondNameCell {
  max-width: 320px;
  width: 100%;
  margin-right: 47px;
}
div.patronymicCell {
  max-width: 320px;
  width: 100%;
  margin-right: 47px;
}
div.companyCell {
  max-width: 320px;
  width: 100%;
  margin-right: 47px;
}
div.contactCell {
  width: 220px;
}
div.positionCell {
  max-width: 320px;
  width: 100%;
  margin-right: 47px;
}
div.descriptionCell {
  max-width: 320px;
  width: 100%;
  margin-right: 47px;
}
div.cityCell {
  max-width: 320px;
  width: 100%;
  margin-right: 47px;
}
div.typeCell {
  width: 100px;
  padding-right: 20px;
}
label.activeSwitcher {
  margin: 0 !important;
}
div.usersPagination {
  position: absolute;
  bottom: 0;
  right: 0;
}
.table thead th {
  vertical-align: middle !important;
  border: none !important;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.table th,
.table td {
  border: none !important;
  vertical-align: middle !important;
  padding: 5px !important;
}
.searchUser {
  display: flex;
}
td.newUserLineCell {
  padding: 0 !important;
  border: none !important;
}
td.newUserLineCellBlue {
  padding: 0 !important;
  border: none !important;
  width: 260px;
}
td.newUserLineCellTrash {
  border: none !important;
  vertical-align: middle !important;
  padding: 5px !important;
  width: 52px;
}
div.searchIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
div.imgSearhUser {
  padding-right: 10px;
}
div.currentUserForm {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  display: flex;
}
td.currentUserForm {
  border: none !important;
}
div.currentUser {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
div.avaCurrent {
  /* background-color: pink; */
  height: 128px;
  width: 100px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 56px;
}
td.MuiTableCell-root.MuiTableCell-body.avaCurrent {
  border-bottom: none !important;
}
div.currentLogin {
  width: 100%;
}
@media (max-width: 420px) {
  div.usersPagination {
    position: inherit;
  }
}
.addButton {
  margin-left: 47px;
  margin-top: 40px;  
}
.avaAndLogin {
  display: flex;
}
.loginRaw {
  display: flex;
  height: fit-content;
  position: relative;
  width: 100%;
}
.searchLine {
  margin-right: 20px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  font-size: 12px !important;
}
.MuiInputBase-input {
  height: fit-content;
}
/* .MuiInputBase-input.MuiInput-input {
  font-size: 14px !important;
 

} */
button.addButtonBtn {
  text-transform: inherit;
  font-weight: 500;
  color: white;
  background-color: rgba(0, 161, 247, 1);
}
button.addButtonBtn:hover {
  background-color: #1c8fce !important;
  color: white !important;
  /* border:1px solid #1c8fce; */
}
.loginField {
  width: 100%;
  max-width: 320px;  
}

.loginFieldError {
  width: 100%;
  max-width: 320px;
  .MuiInput-underline:before {
    border-bottom-color: #E2333A;
  }  
}

.underLoginFieldError {
  color: #D43A3E;
  line-height: 24px;
  margin-left: 8px;
}

.errorAlias {
  color: #D43A3E;
  line-height: 24px;
  margin-top: 12px;
  margin-left: 181px;
}


.contactRow {
  width: fit-content;
  padding: 5px 0 5px 0;
}
.cardWrapper{
  padding-bottom: 10px;
  padding-top: 10px;
  overflow: scroll;
  max-height: 100vh;
  height: 100vh;
}
.allContacts {
  display: flex;
}
.contactBlock {
  width: fit-content;
  padding-right: 47px;
}
.emailDomain {
  position: relative;
  width: 100%;
  display: flex;
}
div.row {
  width: 100%;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  height: 100%;
}
.MuiFormControl-root.MuiTextField-root.email {
  padding-right: 5px;
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid  !important;
}
.MuiInput-underline:after {
  border-bottom: 0 solid #3f51b5 !important;
}
.MuiFormControlLabel-root {
  margin-bottom: 25px;
}
.MuiSwitch-thumb {
  margin-top: -3px;
}
.recovery-switch {
  display: flex;
  flex-direction: column;
}
.MuiPaper-root.searchLine.MuiPaper-elevation1.MuiPaper-rounded {
  background-color: transparent !important;
}
.MuiTableCell-root.MuiTableCell-body.userAva {
  width: 40px;
  padding: 8px 20px !important;
  border-bottom: none !important;
}
.MuiTableCell-root.MuiTableCell-body.userCells {
  border-bottom: none !important;
  white-space: nowrap;
  overflow: hidden; 
  
  /* padding: 5px; */
  text-overflow: ellipsis; 
  max-width: 250px;
}
/* .MuiInput-underline:before {
  border-bottom: 1px solid rgba(176, 167, 167, 0.42) !important;
} */
.results-title {
  margin-left: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.newUserPlus {
  cursor: pointer;
  color: #1c8fce;
  font-weight: 700;
}
.AddIcon {
  cursor: pointer;
}
.backButton {
  margin-left: 45px;
}
.deleteUserText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgb(243, 74, 82);
  font-weight: 600;
  padding: 12px 5px;
}
.deleteUserText:hover {
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 5px;
}

.MuiFormLabel-root.mainEmailLabel {
  color: black;
  position: relative;
  top: 23px;
  padding: 0 5px 0 5px;
}
.MuiFormLabel-root.secondEmailLabel {
  display: inline;
  position: relative;
  color: black;
  top: 22px;
  margin: 0 5px
}
.backBtnIcon .MuiButton-label {
  text-transform: none;
  font-weight: 600;
}
.deleteUserBtnIcon .MuiButton-label {
  text-transform: none;
  font-weight: 600;
  color: red;
}
.newUserBtn .MuiButton-label{
  text-transform: none;
  font-weight: 600;  
}

.newUserBtn:hover {
   background-color: rgba(0, 171, 247, 1) !important;
}
.MuiButtonBase-root.backBtnIcon {
  margin-left: 25px;
}
.MuiButtonBase-root.backBtnIcon:hover {
  background-color: rgba(0, 171, 247, 1) !important;
  color: black !important;
}
.MuiButtonBase-root.deleteUserBtnIcon:hover {
  color: black;
}
.MuiButtonBase-root.newUserBtn:hover {
  color: black;
}
td.userTableCell {
  border-bottom: none !important;
}

.MuiTableRow-root.userRow:hover {
  background-color: rgba(0, 171, 247, 1) !important;
}

.MuiTableRow-root.userRow.Mui-selected,
.MuiTableRow-root.userRow.Mui-selected:hover {
  background-color: rgba(0, 161, 247, 0.15) !important;
}

.row_recovery-switch{
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 30px;
    margin-bottom:16px;
    height:26px
}

.mainEmailLabel {
  color: black;
  position: relative;
  top:22px;
  margin: 0 5px 0 5px;
}

