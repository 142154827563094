.loadingCell {
  padding: 0 !important;
}
.resourceGroupsMain {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}
td.resourceGroupsListCell {
  border-bottom: none !important;
}
.resourceGroupsHeader {
  background-color: transparent !important;
}
.MuiTableRow-root.resourceGroupsHeaderRow.MuiTableRow-hover:hover {
  background-color: rgba(0, 161, 247, 0.15);
  color: white;
}
.MuiTableRow-root.resourceGroupsHeaderRow.MuiTableRow-hover.Mui-selected {
  background-color: rgba(0, 171, 247, 1) !important;
  color: white;
}

td.newResourceColumnTitle {
  color: rgba(0, 153, 243, 1);
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  padding: 14px;
}
td.plusImg {
  width: 12px !important;
  padding: 7px 14px !important;
}
td.trashTitle {
  text-align: end;
  padding: 7px 14px !important;
}
img.trashTitle {
  padding: 8px;
}
td.avatarCell {
 
  width: 40px !important;
 
}
.MuiAvatar-root.MuiAvatar-circle.avatarResource.MuiAvatar-colorDefault {
  margin: 0 10px;
}

button.cancelDelete {
  text-transform: none;
  border: 1px solid #ccc;
  font-size: 14px;
  line-height: 14px;
  padding: 9px 24px;
  color: grey;
}
button.cancelDelete:hover {
  text-transform: none;
  border: 1px solid #ccc;
  background-color: rgba(0, 0, 0, 0.04);
  font-size: 14px;
  line-height: 14px;
  padding: 9px 24px;
  color: grey;
}
button.delete {
  text-transform: none;
  border: 1px solid rgba(250, 92, 99, 1);
  font-size: 14px;
  line-height: 14px;
  padding: 9px 24px;
  color: white;
  background-color: rgba(250, 92, 99, 1);
}
button.saveGroupButton {
  border: 1px solid rgba(0, 161, 247, 1);
  padding: 9px 24px;
  color: white;
  background-color: rgba(0, 161, 247, 1);
  font-size: 14px;
  line-height: 14px;
  text-transform: none;
  margin-top: 32px;
}
button.saveGroupButton:hover {
  border: 1px solid #4f6c7c;
  padding: 9px 24px;
  color: white;
  background-color: #4f6c7c;
  font-size: 14px;
  line-height: 14px;
  text-transform: none;
  transition: background-color 250ms ease;
  margin-top: 32px;
}
button.cancelGroupButton {
  border: 1px solid #bfbfbf;
  padding: 9px 24px;
  font-size: 14px;
  line-height: 14px;
  text-transform: none;
  margin: 32px 17px 0 0;
}
button.cancelGroupButton:hover {
  border: 1px solid #bfbfbf;
  background-color: #bfbfbf;
  padding: 9px 24px;
  font-size: 14px;
  line-height: 14px;
  text-transform: none;
  margin: 32px 17px 0 0;
}
.newGroup {
  font-weight: 700;
  margin-bottom: 32px;
}
.addGroupPlus {
  color: rgba(0, 153, 243, 1);
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
}
.MuiTable-root.resourceGroupTable.MuiTable-stickyHeader {
  padding: 5px;
}
.MuiToolbar-root.MuiToolbar-regular.resourceGroupsToolbar.MuiToolbar-gutters {
  padding: 5px !important;
}
div.goBack {
  margin-bottom: 40px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
  display: flex;
  padding-top: 16px;
}
div.newResourceForm {
  padding: 0px 47px;
}
div.newResourceBack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
}
div.resourceAva {
  background-color: rgba(183, 131, 248, 1);
  width: 128px;
  height: 128px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div.existingResourceAva {
  background-color: rgba(99, 163, 249, 1);
  width: 128px;
  height: 128px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

img.resourcesAvatar {
  height: 65px;
}
.resourceName
  .resourceCity
  .resourceAddress
  .selectedAddress
  .resourceDescription {
  font-size: 14px;
}
.MuiFormControl-root.MuiTextField-root.resourceName {
  padding-right: 10px !important;
}
button.cancelButton {
  text-transform: inherit;
  border-radius: 2px;
  font-weight: 700;
  border: 1px solid #ccc;
  margin: 32px 20px 0 0;
}
button.cancelButton:hover {
  border: 1px solid #5a7787;
  background-color: #5a7787;
  color: white !important;
}
button.saveButton {
  text-transform: inherit;
  border-radius: 2px;
  font-weight: 700;
  color: white;
  border: 1px solid rgba(0, 161, 247, 1);
  background-color: rgba(0, 161, 247, 1);
  margin-top: 32px;
}
button.saveButton:hover {
  background-color: #1c8fce !important;
  color: white !important;
}
div.addressDropdown {
  margin-top: 16px;
  margin-left: 5px;
}
.MuiTableHead-root.resourceTableHead {
  border-bottom: 1px solid #ececec;
}
.MuiTableCell-root.MuiTableCell-head.resourcesGroupTableHead.MuiTableCell-alignLeft.MuiTableCell-paddingNone {
padding: 15px 5px !important;
}
.MuiTableCell-root.MuiTableCell-body.trashRight {
width: 36px;
}