div.loginPage {
  width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
div.login {
  width: 100%;
  height: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 150px;
}
div.mainLoginPage {
  height: 100%;
  width: 100%;
  padding: 20px;
}
.loginHeader {
  text-align: end;
  display: flex;
  float: right;
}

.loginPart {
  display: inline-flex;
}

button.entranceButton {
  margin-top: 1rem;
  border: 1px solid rgba(0, 154, 242, 1);
  background-color: rgba(0, 154, 242, 1);
  color: white;
  border-radius: 5px;
  width: 164px;
  height: 36px;
  text-transform: none !important;
  font-size: 16px;
  line-height: 24px;
}

button.entranceButton:hover {
  background-color: rgba(0, 154, 242, 0.7);
  border: 1px solid rgba(0, 154, 242, 0.7);
}
.MuiButton-root:hover {
  background-color: #22c4ed;
  color: white;
}

.loginRows {
  width: 100%;
  margin-top: 5% !important;
}

.recoveryPassword {
  color: rgba(0, 142, 224, 1);
  text-align: end;
  font-size: 16px;
  margin: 13px 0 39px 0;
  font-weight: 600;
}

img.logoImg {
  width: 163px;
}

/* .MuiInput-underline:before {
  border-bottom: none;
} */

/* .MuiInput-underline:before {
  border-bottom: none;
} */

span.credentialsRequired {
  text-align: start;
  color: red;
}

.changeLanguage {
  display: flex;
}
.form-control {
  border: none !important;
}

.form-control:focus {
  border: none !important;
  box-shadow: none !important;
}
.btn-secondary {
  background-color: transparent !important;
  border: none !important;
}
.dropdown-menu {
  min-width: auto;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none !important;
}
.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none !important;
}
@media (max-width: 420px) {
  div.loginPage {
    width: 300px;
  }
}
.helpImg {
  margin-left: 10px;
}
.helpImg:hover {
  background-color: #e9e6e6;
  border-radius: 50%;
}
.langBtn {
  height: 32px;
  width: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}
.langBtn:hover {
  background-color: #e9e6e6;
  border-radius: 50%;
}
.helpWindow {
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
}

.helpWindowPaper {
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  max-height: 564px;
  width: 400px;
  height: 230px;
  margin: 10px;
  border-radius: 3px;
}
.MuiBackdrop-root {
  background-color: transparent !important;
}
div.question {
  padding: 20px;
  border-bottom: 2px solid #efefef;
  color: rgba(0,0,0,.7);

}
div.askUs {
  padding: 20px;
  color: #00a1f7;
    text-decoration: underline;
}