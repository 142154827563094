.underMailFieldError {
    color: #D43A3E;
    line-height: 24px;
    margin-left: 8px;
  }

.mailFieldError {
     /* width: 100%; */
    /* max-width: 320px; */
    .MuiInput-underline:before {
      border-bottom-color: #E2333A !important;
    }  
  }
  