.makeStyles-rootSidebar-1 {
  background-color: #ededed !important;
  display: "flex";
  height: 100vh !important;
}
/* .MuiPaper-root {
    background-color: transparent !important;
} */
/* .MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: rgba(0, 161, 247, 0.15) !important;
} */
/* .MuiListItem-button:hover {
  background-color: rgba(0, 171, 247, 1) !important;
} */
/* .MuiTableRow-root.Mui-selected,
.MuiTableRow-root.Mui-selected:hover {
  background-color: #ededed !important;
} */
button.saveButton {
  text-transform: inherit;
  border-radius: 2px;
  font-weight: 700;
}
button.saveButton:hover {
  color: white;
  background-color: #ededed;
}
.greyBar { 
  position: fixed;   
  height: 100vh !important;
  width: 56px;
  background: url("../assets/greyBar.svg");
}
.logoImgLeftBar {
  position: fixed;
  left: 0;
  top: 0;
  height: 56px;
  width: 56px;
}
.langButton {
  position: fixed;
  width: 56px;
  text-align: center;
  bottom: 56px;
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.logoutButton {
  width: 56px;
  text-align: center;
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.help {
  position: fixed;
  bottom: 0;
}
.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock {
  font-size: 14px;
}

.langButton:hover {
  background-color: #ffffff47;
  border-radius: 50%;
}
.logoutButton:hover {
  background-color: #ffffff47;
  border-radius: 50%;
}
.MuiDialog-root.changeLangiaguDialog {
  position: fixed;
  z-index: 1300;
  /* right: inherit !important; */
  bottom: 0px;
  /* top: inherit !important; */
  left: 0px;
}
.mainMenu {
margin-top: 24px;
}
.mainMenu .sidebar_pgs-link{
  border-top: #19252E29 1px solid
}

.tenantRow {
  display: flex; 
  align-items: center;
  align-self: stretch;
}

.tenantName {  
  width: 195px;
  padding-left: 16px;
  padding-top: 6px;
  padding-bottom: 4px;
  height: 32px;
  /* font-family: Roboto; */
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
 
}

.upArrow{ 
  width: 24px;
  height: 24px;  
  margin-left: 16px;
}

.upArrow-rotate{ 
  width: 24px;
  height: 24px;  
  margin-left: 16px;
  transform: rotate(180deg)
}

.menuListItemSideBar {
  /* &:hover { background-color:  #D5EDFA !important}; */
  /* &:focus { background-color: rgba(213, 237, 250, 1) !important} */
 &.Mui-selected {background-color: rgba(213, 237, 250, 1) !important}
}


.menuListItem.MuiListItem-gutters {
 padding-left: 24px;
}

.menuListItem.MuiListItem-root {
  &.Mui-selected {
    background-color:transparent
  }
  padding-top: 0px;
  padding-bottom: 0px;
  height: 44px;
  
}

.menuListItem .MuiTypography-body1 {
  font-size: 14px;
  /* font-family: "Roboto"; */
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.00938em;  
  color: #202020;
}